.parent {
  height: 100vh;
}

.pl {
  padding: 20px 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pl-texts {
  width: 65%;
}

.pl-title {
  font-size: 60px;
  padding: 50px;
}

.pl-subtitle {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #cc6969;
  /* display: flex;
  align-items: center; */
  text-align: center;
}

.pl-subtitle-2 {
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  padding: 10px 10px 10px 10px;
}

.pl-desc {
  padding: 0px 0px 20px 0px;
}

.row {
  padding: 20px 120px;
}

.column {
  float: left;
  width: 33.33%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

@media screen and (max-width: 600px) {

  .parent {
    flex-direction: column;
    text-align: center;
    margin-top: 50px;
  }

  .pl {
    padding: 10px;
  }

  .pl-title {
    padding: 10px;
  }

  .pl-texts {
    width: 100%;
  }

  .pl-desc {
    display: none;
  }

  .column {
    width: 100%;
  }

  .row {
    padding: 10px;
  }

  .pl-subtitle {
    padding-top: 20px;
  }
}